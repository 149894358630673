import React from "react"
import "./sidebar.css"

import photo from "../../images/photo.jpg"
import aws_security from "../../images/security.png"
import aws_cp from "../../images/cp.png"
import aws_dev from "../../images/dev.png"
import aws_sa from "../../images/sa.png"
import aws_sysops from "../../images/sysops.png"
import devops_pro from "../../images/devopspro.png"

const Bio = ({ author, tagline }) => {

    return (
        <div className="bio-main w-75">
            <img src={photo} style={{ maxWidth: `100px` }} className="profile-img" alt="" />
            <h3 className="mt-2 author-bio">{author}</h3>
            <small className="text-muted">{tagline}</small>
            <div style={{ paddingTop: `20px` }}>
                <img src={devops_pro} style={{ maxWidth: `75px` }} alt="" />  
                <img src={aws_security} style={{ maxWidth: `75px` }} alt="" />
                <img src={aws_dev} style={{ maxWidth: `75px` }} alt="" />
                <img src={aws_sa} style={{ maxWidth: `75px` }} alt="" />
                <img src={aws_sysops} style={{ maxWidth: `75px` }} alt="" />
                <img src={aws_cp} style={{ maxWidth: `75px` }} alt="" />
            </div>
        </div>
    )
}

export default Bio
import React from "react"
import {
    FaLinkedin,
    FaDev
} from "react-icons/fa"


const SocialLinks = ({ contacts }) => {
    return (
        <div className="social-links float-right mr-4">
            <a className="text-primary ml-4"
                href={contacts.linkedin}>
                <span title="Linked In">
                    <FaLinkedin size={40} style={{ color: "#30092A", background: "white", "border-radius": "4px"}} />
                </span>
            </a>
            <a className="text-info ml-4"
                href={contacts.dev}>
                <span title="Dev.to">
                    <FaDev size={40} style={{ color: "#30092A", background: "white", "border-radius": "4px" }} />
                </span>
            </a>
        </div>
    )
}

export default SocialLinks